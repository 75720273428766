import { getSearchParams } from "../../../utils/request";
import { api } from "../../api";

const locationsApi = api.injectEndpoints({
  endpoints: (builder) => ({
    getLocations: builder.query({
      query: ({ page = 1, limit = 20, ...rest }) => {
        const query = getSearchParams({
          legacy: false,
          page,
          limit,
          ...rest,
        });
        return `locations?${query}`;
      },
      providesTags: (data) =>
        data?.location
          ? [
              ...data.location.map(({ _id }) => ({
                type: "Locations",
                id: _id,
              })),
              { type: "Locations", id: "LIST" },
            ]
          : [{ type: "Locations", id: "LIST" }],
    }),
    addLocation: builder.mutation({
      query({ data, parentId }) {
        return {
          url: "locations",
          method: "POST",
          body: data,
        };
      },
      invalidatesTags: [{ type: "Locations", id: "LIST" }],
    }),
    getLocation: builder.query({
      query: ({ id, ...rest }) => `locations/${id}?${getSearchParams(rest)}`,
      transformResponse: (response) => response.data,
      providesTags: (_result, _error, data) => {
        return [{ type: "Locations", id: data.id }];
      },
    }),
    updateLocation: builder.mutation({
      query({ id, data }) {
        return {
          url: `/locations/${id}`,
          method: "PUT",
          body: data,
        };
      },
      invalidatesTags: (_result, _error, { id }) => {
        return [{ type: "Locations", id }];
      },
    }),
  }),
  overrideExisting: false,
});

export const {
  useGetLocationsQuery,
  useAddLocationMutation,
  useGetLocationQuery,
  useUpdateLocationMutation,
} = locationsApi;
