import React from "react";
import { useForm } from "../../../../hooks/useForm";
import {
  Card,
  Button,
  DateRangePicker,
  Checkbox,
  Select,
  Badge,
  MultiSelect,
} from "../../../../shared";
import {
  // useGetDriverReportsQuery,
  useGenerateDriverReportMutation,
  // useGetDriverReportQuery,
} from "../../../../redux/services/reports/reportsApi";

/**
 * DriverReports component displays and manages driver-specific reports
 * @param {Object} props - Component props
 * @param {Object} props.driver - Driver object containing driver information
 * @param {string} props.driverStatus - Current status of driver data loading
 */
const DriverReports = ({ driver, driverStatus }) => {
  const { form, updateForm, isValid } = useForm({
    validations: {
      selectedYear: { required: true },
      selectedMonths: {
        custom: {
          isValid: (value, { form }) => {
            // Only require months selection for monthly reports
            return form.reportType !== "monthly" || (value && value.length > 0);
          },
          message: "Please select at least one month",
        },
      },
      reportType: { required: true },
      selectedMetrics: { required: true },
    },
  });

  console.log("form", form);

  const [generateReport, { data: reports, isLoading }] =
    useGenerateDriverReportMutation();

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (!isValid) return;

    const reportConfig = {
      driverId: driver._id,
      ...form,
    };

    try {
      await generateReport(reportConfig).unwrap();
    } catch (error) {
      console.error("Failed to create report:", error);
    }
  };

  const reportTypeOptions = [
    { value: "monthlyPayout", label: "Monthly Payout Report", type: "monthly" },
    { value: "yearlyPayout", label: "Yearly Payout Report", type: "yearly" },
    // { value: "monthlyPerformance", label: "Monthly Performance Report" },
    // { value: "yearlyPerformance", label: "Yearly Performance Report" },
    // { value: "legStatus", label: "Leg Status Analysis" },
    // { value: "payoutDetail", label: "Detailed Payout Report" },
    // { value: "performance", label: "Performance Report" },
  ];

  const yearOptions = Array.from({ length: 5 }, (_, i) => {
    const year = new Date().getFullYear() - i;
    return { value: year, label: year.toString() };
  });

  const monthOptions = [
    { value: 0, label: "January" },
    { value: 1, label: "February" },
    { value: 2, label: "March" },
    { value: 3, label: "April" },
    { value: 4, label: "May" },
    { value: 5, label: "June" },
    { value: 6, label: "July" },
    { value: 7, label: "August" },
    { value: 8, label: "September" },
    { value: 9, label: "October" },
    { value: 10, label: "November" },
    { value: 11, label: "December" },
  ];

  const metricOptions = [
    { value: "mileage", label: "Total Mileage" },
    { value: "earnings", label: "Earnings" },
    { value: "jobs", label: "Job Completion" },
    { value: "waitTime", label: "Wait Time" },
    { value: "performance", label: "Performance Metrics" },
  ];

  const renderDateSelection = () => {
    if (form.reportType?.type === "yearly") {
      return (
        <div>
          <label className="block text-sm font-medium text-gray-700 mb-2">
            Select Year
          </label>
          <Select
            id="selectedYear"
            value={form.selectedYear?.value}
            onChange={(value) => updateForm({ id: "selectedYear", value })}
            options={yearOptions}
          />
        </div>
      );
    }

    if (form.reportType?.type === "monthly") {
      return (
        <div className="space-y-2">
          <div>
            <label className="block text-sm font-medium text-gray-700 mb-2">
              Select Year
            </label>
            <Select
              id="selectedYear"
              value={form.selectedYear?.value}
              onChange={(value) => updateForm({ id: "selectedYear", value })}
              options={yearOptions}
            />
          </div>
          <div>
            <label className="block text-sm font-medium text-gray-700 mb-2">
              Select Months
            </label>
            <MultiSelect
              id="selectedMonths"
              value={
                form.selectedMonths?.length > 0
                  ? monthOptions
                      .filter((month) =>
                        form.selectedMonths.includes(month.value)
                      )
                      .map((month) => month.label)
                      .join(", ")
                  : "Select month"
              }
              onChange={(values) => {
                // values will be an array of selected options
                const selectedValues = values.map((item) => ({
                  value: item.value,
                  label: item.label,
                }));
                updateForm({ id: "selectedMonths", value: selectedValues });
              }}
              options={monthOptions}
              // label={
              //   form.selectedMonths?.length > 0
              //     ? monthOptions
              //         .filter((month) =>
              //           form.selectedMonths.includes(month.value)
              //         )
              //         .map((month) => month.label)
              //         .join(", ")
              //     : "Select month"
              // }
            />
          </div>
        </div>
      );
    }

    return null;
  };

  return (
    <div className="grid grid-cols-1 lg:grid-cols-2 gap-4">
      {/* Report Creation Card */}
      <Card className="p-4">
        <h3 className="text-lg font-semibold mb-4">Create New Report</h3>
        <form onSubmit={handleSubmit} className="space-y-4">
          <div>
            <label className="block text-sm font-medium text-gray-700 mb-2">
              Report Type
            </label>
            <Select
              id="reportType"
              value={form.reportType?.label}
              onChange={(value) => {
                updateForm(() => ({
                  reportType: value,
                  selectedMonths: [], // Reset months when changing report type
                }));
              }}
              options={reportTypeOptions}
            />
          </div>

          {renderDateSelection()}

          {/* <div>
            <label className="block text-sm font-medium text-gray-700 mb-2">
              Include Metrics
            </label>
            <div className="grid grid-cols-2 gap-2">
              {metricOptions.map((metric) => (
                <Checkbox
                  key={metric.value}
                  id="selectedMetrics"
                  label={metric.label}
                  value={metric.value}
                  checked={form.selectedMetrics?.includes(metric.value)}
                  onChange={(e) => {
                    const currentMetrics = form.selectedMetrics || [];
                    const newMetrics = e.target.checked
                      ? [...currentMetrics, metric.value]
                      : currentMetrics.filter((m) => m !== metric.value);
                    updateForm({ id: "selectedMetrics", value: newMetrics });
                  }}
                />
              ))}
            </div>
          </div> */}

          <Button type="submit" loading={isLoading} disabled={isLoading}>
            Generate Report
          </Button>
        </form>
      </Card>

      {/* Reports List Card */}
      <Card className="p-4">
        <h3 className="text-lg font-semibold mb-4">Previous Reports</h3>
        {isLoading ? (
          <div>Loading reports...</div>
        ) : (
          <div className="space-y-2">
            {reports?.map((report) => (
              <div
                key={report._id}
                className="p-3 border rounded hover:bg-gray-50"
              >
                <div className="flex justify-between items-center">
                  <div className="space-y-1">
                    <div className="flex items-center space-x-2">
                      <span>
                        {new Date(report.createdAt).toLocaleDateString()}
                      </span>
                      <Badge label={report.reportType} />
                    </div>
                    <div className="text-sm text-gray-500">
                      Metrics: {report.metrics.join(", ")}
                    </div>
                  </div>
                  <Button size="sm" variant="outline">
                    Download
                  </Button>
                </div>
              </div>
            ))}
          </div>
        )}
      </Card>
    </div>
  );
};

export default DriverReports;
